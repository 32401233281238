// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Cfcard7 from "../../blocks/cfcard7/src/Cfcard7";
import Cfchildquestionconcept from "../../blocks/cfchildquestionconcept/src/Cfchildquestionconcept";
import Cfmultipleframeworkforfrontend from "../../blocks/cfmultipleframeworkforfrontend/src/Cfmultipleframeworkforfrontend";
import Cfmultipleframeworktobesupported from "../../blocks/cfmultipleframeworktobesupported/src/Cfmultipleframeworktobesupported";
import Cfsaveasdraft1 from "../../blocks/cfsaveasdraft1/src/Cfsaveasdraft1";
import Cfmoveupanddown from "../../blocks/cfmoveupanddown/src/Cfmoveupanddown";
import Cfcard10 from "../../blocks/cfcard10/src/Cfcard10";
import Cfcarboncalculatorformdropdown from "../../blocks/cfcarboncalculatorformdropdown/src/Cfcarboncalculatorformdropdown";
import Cfintegrationwithkrypchedera from "../../blocks/cfintegrationwithkrypchedera/src/Cfintegrationwithkrypchedera";
import Cfquestionuniqueidentifierchange from "../../blocks/cfquestionuniqueidentifierchange/src/Cfquestionuniqueidentifierchange";
import Cfcard8 from "../../blocks/cfcard8/src/Cfcard8";
import Cfconditionquestion from "../../blocks/cfconditionquestion/src/Cfconditionquestion";
import Convertfile2 from "../../blocks/convertfile2/src/Convertfile2";
import Cfscoringsinglemultipleselectionlimitation from "../../blocks/cfscoringsinglemultipleselectionlimitation/src/Cfscoringsinglemultipleselectionlimitation";
import Cfcard9 from "../../blocks/cfcard9/src/Cfcard9";
import Cfcarboncalculatorapiintegration from "../../blocks/cfcarboncalculatorapiintegration/src/Cfcarboncalculatorapiintegration";



const routeMap = {
Cfcard7:{
 component:Cfcard7,
path:"/Cfcard7"},
Cfchildquestionconcept:{
 component:Cfchildquestionconcept,
path:"/Cfchildquestionconcept"},
Cfmultipleframeworkforfrontend:{
 component:Cfmultipleframeworkforfrontend,
path:"/Cfmultipleframeworkforfrontend"},
Cfmultipleframeworktobesupported:{
 component:Cfmultipleframeworktobesupported,
path:"/Cfmultipleframeworktobesupported"},
Cfsaveasdraft1:{
 component:Cfsaveasdraft1,
path:"/Cfsaveasdraft1"},
Cfmoveupanddown:{
 component:Cfmoveupanddown,
path:"/Cfmoveupanddown"},
Cfcard10:{
 component:Cfcard10,
path:"/Cfcard10"},
Cfcarboncalculatorformdropdown:{
 component:Cfcarboncalculatorformdropdown,
path:"/Cfcarboncalculatorformdropdown"},
Cfintegrationwithkrypchedera:{
 component:Cfintegrationwithkrypchedera,
path:"/Cfintegrationwithkrypchedera"},
Cfquestionuniqueidentifierchange:{
 component:Cfquestionuniqueidentifierchange,
path:"/Cfquestionuniqueidentifierchange"},
Cfcard8:{
 component:Cfcard8,
path:"/Cfcard8"},
Cfconditionquestion:{
 component:Cfconditionquestion,
path:"/Cfconditionquestion"},
Convertfile2:{
 component:Convertfile2,
path:"/Convertfile2"},
Cfscoringsinglemultipleselectionlimitation:{
 component:Cfscoringsinglemultipleselectionlimitation,
path:"/Cfscoringsinglemultipleselectionlimitation"},
Cfcard9:{
 component:Cfcard9,
path:"/Cfcard9"},
Cfcarboncalculatorapiintegration:{
 component:Cfcarboncalculatorapiintegration,
path:"/Cfcarboncalculatorapiintegration"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;